import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import PageLoader from './components/Common/PageLoader'
import VueElementLoading from "vue-element-loading";
import ServerError from './components/Common/500'
Vue.component("VueElementLoading", VueElementLoading);
import { VueEditor } from "vue2-editor";
Vue.component(VueEditor);
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component(VueCropper);
Vue.component('PageLoader', PageLoader)
Vue.component('ServerError', ServerError)
Vue.config.productionTip = false

window.axios = require('axios')
//     axios.defaults.baseURL = 'http://192.168.51.187:5000';
//     Vue.prototype.baseURL = "http://192.168.51.187:5000"
//     Vue.prototype.mediaURL = "http://192.168.51.187:5000/u/"
// Vue.prototype.mediaURLnew = "http://192.168.51.187:5000/wp/"
// Vue.prototype.mediaURLnews = "http://192.168.51.187:5000/file/get/"

axios.defaults.baseURL = 'https://wtiadmin.in';
Vue.prototype.baseURL = "https://wtiadmin.in"
Vue.prototype.mediaURL = "https://wtiadmin.in/u/"
Vue.prototype.mediaURLnew = "https://wtiadmin.in/wp/"
Vue.prototype.mediaURLnews = "https://wtiadmin.in/file/get/"






new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')