import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/appDashboard",
          name: "appDashboard",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Common/appDashboard"),
        },
        {
          path: "/home",
          name: "home",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/home"),
        },
        //events
        {
          path: "/EventsPage",
          name: "EventsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/EventsPage"),
        },
        {
          path: "/addEvent",
          name: "addEvent",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/addEvent"),
        },
        {
          path: "/eventEdit",
          name: "eventEdit",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/eventEdit"),
        },
        {
          path: "/eventImage",
          name: "eventImage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/eventImage"),
        },
        {
          path: "/bannerImage",
          name: "bannerImage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/bannerImage"),
        },
        {
          path: "/galleryImage",
          name: "galleryImage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/galleryImage"),
        },
        {
          path: "/galleryVideo",
          name: "galleryVideo",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/galleryVideo"),
        },



        {
          path: "/galleryVideoLink",
          name: "galleryVideoLink",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/galleryVideoLink"),
        },

        {
          path: "/UserListPage",
          name: "UserListPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/UserListPage"),
        },


        {
          path: "/DetailedViewPage",
          name: "DetailedViewPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Events/DetailedViewPage"),
        },





        //
        {
          path: "/subCategory",
          name: "subCategory",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/SubCategory/subCategory"),
        },
      
        {
          path: "/contactCategory",
          name: "contact category",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Contact/contactCategory"),
        },
        {
          path: "/contactList",
          name: "contact list",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Contact/contactList"),
        },
        {
          path: "/contactDetails",
          name: "contact details",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Contact/contactDetails"),
        },
        {
          path: "/about",
          name: "about",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/About/about"),
        },
        {
          path: "/socialMedias",
          name: "socialMedias",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/SocialMedia/socialMedias"),
        },
        {
          path: "/services",
          name: "services",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Services/services"),
        },
        {
          path: "/serviceList",
          name: "serviceList",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Services/serviceList"),
        },
        {
          path: "/quote",
          name: "Quote",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Quote/quote"),
        },
        {
          path: "/quoteDetails",
          name: "QuoteDetails",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Quote/quoteDetails"),
        },

     

       
        {
          path: "/page1",
       
        
          component: () => import("./components/TestPage"),
        },

     
         
      ],
    },
    {
      path: "/",
      name: "login",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/login"),
    },
    // {
    //     path: '/',
    //     props: true,
    //     name: 'AdminDashboard',
    //     component: () =>
    //         import ('./components/Common/appDashboard'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "login", params: { lastPage: from } });
    return;
  }
  if (store.state.isLoggedIn == true) {
    axios({
      method: "GET",
      url: "/media/admin/dashboard",
      headers: {
        "token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
  }
  if (to.name == "login" && store.state.isLoggedIn == true) {
    next({ name: "appDashboard" });
    return;
  }
  next();
});












// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some((route) => route.meta.requireAuth == true) &&
//     store.state.isLoggedIn == false
//   ) {
//     console.log(from);
//     next({ name: "login", params: { lastPage: from } });
//     return;
//   }
//   if (store.state.isLoggedIn == true) {
//     axios({
//       method: "POST",
//       url: "/validate/token",
//       headers: {
//         "token": localStorage.getItem("token"),
//       },
//     })
//       .then((response) => {
//         if (!response.data.status) {
//           store.commit("sessionOut", true);
//           return;
//         }
//       })
//       .catch((err) => {
//         var msg = err;
//         console.log(msg);
//       });
//   }
//   if (to.name == "login" && store.state.isLoggedIn == true) {
//     next({ name: "dashboard" });
//     return;
//   }
//   next();
// });
export default router;
